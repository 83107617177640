import format from "date-fns/format";

import {
  ChapterBudgetTimeline,
  ChapterClientNameType,
  ChapterDeliveryTrackerHistory,
  ChapterDeliveryTrackerHistoryGetById,
  ChapterDeliveryTrackerItem,
  ChapterDesignConfiguration,
  ChapterDocument,
  ChapterGuest,
  ChapterItem,
  ChapterOpportunity,
  ChapterOpportunityProperties,
  ChapterSiteGalleryDocument,
  ChapterSiteVisit,
  ChapterSmartSheetDetails,
  ChapterStepSelection,
  ChapterUpdateBudgetTimeline,
  ChapterVersionData,
  ChapterVillaDetails,
} from "../types/chapter";
import {
  DevelopmentClientLedger,
  DevelopmentClientLedgerItem,
  DevelopmentClientLedgerTotal,
} from "../types/development-opportunity";
import { IndexedValue } from "../types/indexed-value";
import { Pagination } from "../types/pagination";
import { IChapterService } from "../types/services/chapter-service";
import { DELETE, FetchResult, GET, PATCH, POST } from "../utils/fetch";

type OpportunityAddResponse = {
  slug: string;
};

type OpportunityListResponse = {
  opportunities: any[];
  paginate: Pagination;
};

type OpportunityResponse = {
  opportunity: any;
};

type ChapterDesignConfigurationResponse = {
  opportunity: any;
  design_status: any;
};

type SiteVisitResponse = {
  site_visit: any[];
};

type PropertyOptionsResponse = {
  properties: any[];
  paginate: Pagination;
};

type PropertySelectionResponse = {
  opportunity_properties: any;
};

type DeliveryTrackerClientResponse = {
  clients: any;
};

type DeliveryTrackerHistoryResponse = {
  history: any;
  paginate: any;
};

type DeliveryTrackerHistoryGetByIdResponse = {
  delivery_timeline_detail: any;
};

type DeliveryTrackerListResponse = {
  delivery_trackers: any[];
  paginate: Pagination;
};

type StepSelectionResponse = {
  steps: any;
};

type SmartSheetResponse = {
  ops_budget_date: string;
  ops_actual_date: string;
  client_communication_date: string;
};

type PaymentVersionResponse = {
  versions: any[];
};

type GuestResponse = {
  guests: any[];
};

type ChapterHoDocumentsResponse = {
  home_owner_attachments: any[];
};

type ChapterDocumentsResponse = {
  documents: any[];
};

type ChapterSiteGalleryResponse = {
  site_galleries: any[];
};

type ClientLedgerListResponse = {
  entries: DevelopmentClientLedgerItem[];
  totals: DevelopmentClientLedgerTotal;
  timeline_type_headers: any[];
};

function getFilterQp(
  phone_no?: string,
  statuses?: string[],
  stages?: string[],
  sources?: string[],
  moved_to?: string[],
  enquired_from?: string,
  enquired_until?: string,
  staff?: string,
  search?: string,
  page?: string,
  page_size?: string,
) {
  const qp = new URLSearchParams();
  if (phone_no) {
    qp.set("mobile", phone_no);
  }
  if (statuses) {
    statuses.forEach((status) => {
      qp.append("status[]", status);
    });
  }
  if (stages) {
    stages.forEach((status) => {
      qp.append("stages[]", status);
    });
  }
  if (sources) {
    sources.forEach((source) => {
      qp.append("sources[]", source);
    });
  }
  if (moved_to) {
    moved_to.forEach((value) => qp.set(value, "true"));
  }
  if (search) {
    qp.set("query", search);
  }
  if (enquired_from) {
    qp.set("start_date", enquired_from);
  }
  if (enquired_until) {
    qp.set("end_date", enquired_until);
  }
  if (staff) {
    qp.set("poc_exec_id", staff);
  }
  if (page) {
    qp.set("page", page);
  }
  if (page_size) {
    qp.set("per_page", page_size);
  }

  return qp;
}

/**
 * Handle Delivery Tracker Query Params
 * @param location
 * @param villa
 * @param assigned_to
 * @param status
 * @param search
 * @param page
 * @param page_size
 * @returns
 */
function getDeliveryFilterQp(
  location?: string[],
  villa?: string,
  assigned_to?: string,
  status?: string[],
  search?: string,
  page?: string,
  page_size?: string,
) {
  const deliveryqp = new URLSearchParams();
  if (location) {
    location.forEach((locations) => {
      deliveryqp.append("location_ids[]", locations);
    });
  }
  if (villa) {
    deliveryqp.set("chapter_property_id", villa);
  }
  if (assigned_to) {
    deliveryqp.set("poc_id", assigned_to);
  }
  if (status) {
    status.forEach((stats) => {
      deliveryqp.append("statuses[]", stats);
    });
  }
  if (search) {
    deliveryqp.set("name", search);
  }
  if (page) {
    deliveryqp.set("page", page);
  }
  if (page_size) {
    deliveryqp.set("per_page", page_size);
  }

  return deliveryqp;
}
class ChapterService implements IChapterService {
  async addOpportunity(
    first_name: string,
    last_name: string,
    email: string,
    phone_no: string,
    location: string,
    source_of_enquiry: string,
    poc_exec: number,
    message: string,
    source_of_enquiry_region: string,
    source_of_enquiry_city: string,
    brand_id: number,
    source_of_enquiry_other?: string,
    poc_head?: number,
    agent?: number,
    customer_id?: number,
  ): Promise<FetchResult<string>> {
    const [countryCode, phoneNo] = phone_no.split(" ");

    const payload: any = {
      email: email || "",
      country_code: countryCode || "",
      mobile: phoneNo || "",
      interested_location: location,
      source: source_of_enquiry,
      source_others: source_of_enquiry_other || "",
      poc_exec_id: poc_exec,
      message: message || "",
      source_city: source_of_enquiry_city || "",
      source_region: source_of_enquiry_region || "",
      brand_id: brand_id,
    };
    let name = first_name;
    if (last_name) {
      name = `${name} ${last_name}`;
    }
    payload.name = name;
    if (poc_head) {
      payload.poc_head_id = poc_head;
    }
    if (agent !== undefined) {
      payload.agent_id = agent;
    }
    if (customer_id) {
      payload.contact_id = customer_id;
    }

    const url = `/api/v2/chapter/opportunities`,
      { response, error } = await POST<OpportunityAddResponse>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    return {
      error: null,
      response: response.slug,
    };
  }

  async getChapterOpportunities(
    phone_no?: string,
    statuses?: string[],
    stages?: string[],
    sources?: string[],
    moved_to?: string[],
    enquired_from?: string,
    enquired_until?: string,
    staff?: string,
    search?: string,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<ChapterItem[]>> {
    const qp = getFilterQp(
      phone_no,
      statuses,
      stages,
      sources,
      moved_to,
      enquired_from,
      enquired_until,
      staff,
      search,
      page,
      page_size,
    );

    const marshalled = qp.toString();

    let url = "/api/v2/chapter/opportunities";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<OpportunityListResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const formatted = response.opportunities.map((each) => {
      const {
          enquired_at,
          interested_area,
          poc_exec,
          slug,
          source,
          status,
          updated_at,
          name,
          email,
          mobile,
          verified,
          country_code,
        } = each,
        phoneNo = `${country_code || ""} ${mobile || ""}`.trim(),
        opportunity: ChapterItem = {
          slug: slug || "",
          name: name || "",
          email: email || "",
          phone: phoneNo,
          interested_in: interested_area || "",
          enquiry_date: enquired_at || "",
          last_modified: updated_at || "",
          source: source || "",
          status: status || "no_rating",
          poc_executive: poc_exec || "",
          verified: verified || false,
        };

      return opportunity;
    });

    return {
      error: null,
      paginate: response.paginate,
      response: formatted,
    };
  }

  getChapterOpportunitiesCSV(
    statuses?: string[],
    stages?: string[],
    sources?: string[],
    moved_to?: string[],
    enquired_from?: string,
    enquired_until?: string,
    staff?: string,
  ): string {
    const qp = getFilterQp(
      undefined,
      statuses,
      stages,
      sources,
      moved_to,
      enquired_from,
      enquired_until,
      staff,
    );

    const marshalled = qp.toString();

    let url = "/api/v2/chapter/opportunities.csv";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    return url;
  }

  async getOpportunity(
    opportunitySlug: string,
  ): Promise<FetchResult<ChapterOpportunity>> {
    const url = `/api/v2/chapter/opportunities/${opportunitySlug}`,
      { error, response } = await GET<OpportunityResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const {
        name,
        current_stage,
        message,
        enquired_at,
        estimated_purchase_date,
        id,
        interested_area,
        interested_home_types,
        interested_location,
        home_layout_id,
        max_budget,
        min_budget,
        property_id,
        poc_exec,
        poc_exec_id,
        poc_head_id,
        post_sales_exec_id,
        slug,
        source,
        source_others,
        source_city,
        source_country,
        source_region,
        status,
        updated_at,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term,
        verified,
        contact_id,
        stages,
        agent_id,
        brand_id,
        lead_migration_details: { display_migartion_cta, source_vertical },
        original_source,
        lead_merges,
      } = response.opportunity,
      opportunity: ChapterOpportunity = {
        id: id,
        opportunity_slug: slug || "",
        enquiry_name: name || "",
        current_stage: current_stage || "",
        message: message || "",
        enquiry_date: enquired_at || "",
        estimated_purchase_date: estimated_purchase_date || "",
        interested_area: interested_area || "",
        interested_home_types: interested_home_types || "",
        interested_location: interested_location || "",
        home_layout_id: home_layout_id || null,
        max_budget: max_budget || "",
        min_budget: min_budget || "",
        property_id: property_id || null,
        poc_executive_name: poc_exec || "",
        poc_executive: poc_exec_id,
        poc_head: poc_head_id,
        post_sales_exec: post_sales_exec_id,
        source_of_enquiry: source || "",
        source_of_enquiry_other: source_others || "",
        source_city: source_city || "",
        source_region: source_region || "",
        source_country: source_country || "",
        status: status || "",
        last_modified: updated_at || "",
        stages: stages || [],
        verified: verified || false,
        customer_id: contact_id,
        agent: agent_id,
        utm_source: utm_source || "",
        utm_medium: utm_medium || "",
        utm_campaign: utm_campaign || "",
        utm_content: utm_content || "",
        utm_term: utm_term || "",
        brand_id: brand_id || null,
        original_source: original_source || null,
        display_migartion_cta: display_migartion_cta || false,
        source_vertical: source_vertical,
        lead_merges: lead_merges || [],
      };

    return {
      error: null,
      response: opportunity,
    };
  }

  async updateOpportunity(
    opportunity_slug: string,
    estimated_purchase_date?: string,
    interested_area?: string,
    interested_home_types?: string[],
    interested_location?: string,
    bhk?: string,
    max_budget?: string,
    min_budget?: string,
    source_of_enquiry?: string,
    source_of_enquiry_other?: string,
    source_city?: string,
    source_country?: string,
    source_region?: string,
    message?: string,
    agent?: string,
    brand_id?: number,
  ): Promise<FetchResult<void>> {
    const template = [
        {
          key: "estimated_purchase_date",
          value: estimated_purchase_date,
        },
        {
          key: "interested_area",
          value: interested_area,
        },
        {
          key: "interested_home_types",
          value: interested_home_types,
        },
        {
          key: "interested_location",
          value: interested_location,
        },
        {
          key: "home_layout_id",
          value: bhk,
        },
        {
          key: "max_budget",
          value: max_budget,
        },
        {
          key: "min_budget",
          value: min_budget,
        },
        {
          key: "source",
          value: source_of_enquiry,
        },
        {
          key: "source_others",
          value: source_of_enquiry_other,
        },
        {
          key: "source_city",
          value: source_city,
        },
        {
          key: "source_country",
          value: source_country,
        },
        {
          key: "source_region",
          value: source_region,
        },
        {
          key: "agent_id",
          value: agent,
        },
        {
          key: "message",
          value: message,
        },
        {
          key: "brand_id",
          value: brand_id,
        },
      ],
      payload = template.reduce((compiled, each) => {
        const { key, value } = each;
        if (value !== undefined) {
          compiled[key] = value;
        }
        return compiled;
      }, {} as { [k: string]: string | number | string[] }),
      url = `/api/v2/chapter/opportunities/${opportunity_slug}`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updatePOC(
    opportunity_slug: string,
    poc_executive?: number,
    poc_head?: number,
    post_sales_exec?: number,
  ): Promise<FetchResult<void>> {
    const template = [
        {
          key: "poc_exec_id",
          value: poc_executive,
        },
        {
          key: "poc_head_id",
          value: poc_head,
        },
        {
          key: "post_sales_exec_id",
          value: post_sales_exec,
        },
      ],
      payload = template.reduce((compiled, each) => {
        const { key, value } = each;
        if (value !== undefined) {
          compiled[key] = value;
        }
        return compiled;
      }, {} as { [k: string]: string | number | string[] }),
      url = `/api/v2/chapter/opportunities/${opportunity_slug}/update_poc`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updateStages(
    opportunity_slug: string,
    checked: number[],
  ): Promise<FetchResult<void>> {
    const payload = {
        checked: checked,
      },
      url = `/api/v2/chapter/opportunities/${opportunity_slug}/stage`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async getChapterDesignConfiguration(
    opportunitySlug: string,
  ): Promise<FetchResult<ChapterDesignConfiguration>> {
    const url = `/api/v2/chapter/opportunities/${opportunitySlug}/design_status`,
      { error, response } = await GET<ChapterDesignConfigurationResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const { opportunity, design_status } = response,
      selection: ChapterDesignConfiguration = {
        design_status: opportunity.design_status || "",
        selected_designs: design_status.map((each: any) => {
          const { area_name, theme_name } = each;
          return {
            area_name: area_name || "",
            theme_name: theme_name || "",
          };
        }),
      },
      formatted = selection;

    return {
      error: null,
      response: formatted,
    };
  }

  /**
   * Used to Migrate Opportunity from chapter to development
   */
  async migrateVertical(
    opportunity_slug: string,
    from_vertical: string,
    to_vertical: string,
  ): Promise<FetchResult<void>> {
    const payload = {
        from_vertical: from_vertical,
        to_vertical: to_vertical,
      },
      url = `/api/v2/migration/${opportunity_slug}/migrate_lead`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  /**
   * Used to get Site Visit Details
   */
  async getSiteVisit(
    opportunity_slug: string,
    vertical: string,
  ): Promise<FetchResult<ChapterSiteVisit[]>> {
    const url = `/api/v2/site_visits/get_visits?vertical=${vertical}&opportunity_slug=${opportunity_slug}`,
      { error, response } = await GET<SiteVisitResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted = response.site_visit.map((each: any) => {
      const {
          assignee_id,
          assignee_name,
          assigned_by_id,
          assigned_by_name,
          cancellation_reason,
          comments,
          guest_name,
          id,
          opportunity_slug,
          opportunity_status,
          description,
          plots,
          rental_properties,
          site_visit_histories,
          status,
          visit_date_time,
          follow_up_time,
          viewing_type,
          vertical,
          opportunity_mobile,
        } = each,
        selection: ChapterSiteVisit = {
          assignedToId: assignee_id,
          assignedToName: assignee_name,
          assignedById: assigned_by_id,
          assignedByName: assigned_by_name,
          cancellation_reason,
          comments,
          guest_name,
          id,
          opportunity_slug,
          opportunity_status,
          description,
          plots,
          site_visit_histories,
          status,
          visit_date_time,
          follow_up_time,
          viewing_type,
          vertical,
          opportunity_mobile,
          rental_properties,
        };

      return selection;
    });

    return {
      error: null,
      response: formatted,
    };
  }

  // Chapter Post Sales

  // Get Villa List
  async getChapterVillaOptions(
    searchVilla: string,
    page: string,
  ): Promise<FetchResult<ChapterVillaDetails[]>> {
    const qp = new URLSearchParams();

    if (searchVilla) {
      qp.set("query", searchVilla);
    }

    if (page) {
      qp.set("page", page);
      qp.set("per_page", "6");
    }

    const marshalled = qp.toString();

    let url = "/api/v2/chapter/properties";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<PropertyOptionsResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted: ChapterVillaDetails[] = response.properties.map((each) => {
      const {
          id,
          slug,
          name,
          active,
          location_details: { city, country, state },
          price,
          property_type,
          room_count,
          sold_out,
          plot_area,
          pool,
          status,
        } = each,
        formatted: ChapterVillaDetails = {
          id,
          slug,
          name: name || "",
          active: active || false,
          city: city || "",
          country: country || "",
          state: state || "",
          price: price || 0,
          property_type: property_type || "",
          room_count: room_count || 0,
          sold_out: sold_out || false,
          plot_area: plot_area || 0,
          pool: pool || 0,
          status: status || "",
        };

      return formatted;
    });

    return {
      error: null,
      response: formatted,
      paginate: response.paginate,
    };
  }

  // Add Villa
  async chapterPostSalesAddVilla(
    opportunity_slug: string,
    selection: string[],
  ): Promise<FetchResult<void>> {
    const payload = {
        opportunity_property_attributes: {
          chapter_property_id: selection[0],
        },
      },
      url = `/api/v2/chapter/opportunities/${opportunity_slug}/opportunity_properties`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  // Get opportunity data
  async getChapterOpportunityProperties(
    opportunity_slug: string,
  ): Promise<FetchResult<ChapterOpportunityProperties>> {
    const url = `/api/v2/chapter/opportunities/${opportunity_slug}/opportunity_properties`,
      { error, response } = await GET<PropertySelectionResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    if (Object.keys(response.opportunity_properties).length === 0) {
      return {
        error: null,
        response: null,
      };
    }

    const {
        id,
        agreement_type,
        chapter_opportunity_id,
        chapter_property_id,
        approval_status,
        latest_payment_schedule_version,
        eligible_steps_demand_letter_count,
        property: {
          id: property_id,
          name,
          property_type,
          sold_out,
          price,
          room_count,
          plot_area,
          pool,
          cta_details: { show_remove_cta },
          location_details: { city, country, state },
        },
        delivery_timelines,
        payment_details: { total_collected, total_payable },
        sub_phase_approval_details: { comment },
      } = response.opportunity_properties ?? {
        property: { cta_details: {} },
        delivery_timelines: [],
      },
      selection: ChapterOpportunityProperties = {
        id: id || 0,
        agreement_type: agreement_type || "",
        approval_status: approval_status || "",
        demand_letter_count: eligible_steps_demand_letter_count || 0,
        property_id: property_id || 0,
        name: name || "",
        city: city || "",
        country: country || "",
        state: state || "",
        price: price || 0,
        property_type: property_type || "",
        sold_out: sold_out || false,
        room_count: room_count || 0,
        plot_area: plot_area || 0,
        pool: pool || 0,
        show_remove_cta: show_remove_cta || false,
        chapter_opportunity_id: chapter_opportunity_id || "",
        chapter_property_id: chapter_property_id || 0,
        total_collected: total_collected || 0,
        total_payable: total_payable || 0,
        version: latest_payment_schedule_version || "",
        approval_comment: comment || "",
        budget_timelines: delivery_timelines.map((each: any) => {
          const {
            id,
            approve,
            payment_date,
            budget_intimation_date,
            step,
            phase,
            sub_phase,
            status,
            amount_pending,
            ops_actual_date,
            ops_budget_date,
            client_communication_date,
            payment_due_date,
            pending_days,
            pending_amount_breakdown,
            selected_bank_accounts,
            is_demand_letter_generated,
            can_generate_demand_letter,
            outstanding_amount,
            tds_paid_status,
            client,
            show_history_cta,
            requested_amount_breakdown: {
              tds_percentage,
            },
          } = each;
          return {
            id,
            approve: approve,
            payment_date,
            budget_intimation_date,
            delivery_milestone: step,
            phase,
            sub_phase,
            status,
            amount_pending,
            ops_actual_date,
            ops_budget_date,
            client_communication_date,
            payment_due_date,
            pending_status: pending_days?.status ?? "",
            days: pending_days?.days ?? "",
            pending_gst_amount:
              pending_amount_breakdown?.pending_gst_amount ?? 0,
            pending_stamp_duty_amount:
              pending_amount_breakdown?.pending_stamp_duty_amount ?? 0,
            pending_registration_amount:
              pending_amount_breakdown?.pending_registration_amount ?? 0,
            pending_tds_amount:
              pending_amount_breakdown?.pending_tds_amount ?? 0,
            baseAmountBankAccount: selected_bank_accounts?.account_type ?? "",
            gstBankAccount: selected_bank_accounts?.gst_account_type ?? "",
            registrationBankAccount:
              selected_bank_accounts?.registration_account_type ?? "",
            stampDutyBankAccount:
              selected_bank_accounts?.stamp_duty_account_type ?? "",
            tdsBankAccount: selected_bank_accounts?.tds_account_type ?? "",
            is_demand_letter_generated: is_demand_letter_generated,
            can_generate_demand_letter: can_generate_demand_letter,
            outstanding_amount: outstanding_amount || 0,
            tds_paid_status: tds_paid_status || "",
            clients: client || null,
            show_history_cta: show_history_cta || false,
            tds_percentage: tds_percentage || 0,
          };
        }),
      },
      formatted = selection;

    return {
      error: null,
      response: formatted,
    };
  }

  // Remove Villa
  async removeChapterVilla(
    opportunity_slug: string,
    selection_id: number,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/chapter/opportunities/${opportunity_slug}/opportunity_properties/${selection_id}`,
      { error } = await DELETE<void>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  //Fetches Delivery tracker listing
  async getChapterDeliveryTracker(
    search?: string,
    location?: string[],
    villa?: string,
    status?: string[],
    assigned_to?: string,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<ChapterDeliveryTrackerItem[]>> {
    const qp = getDeliveryFilterQp(
      location,
      villa,
      assigned_to,
      status,
      search,
      page,
      page_size,
    );

    const queryParam = qp.toString();

    let url = "/api/v2/chapter/delivery_trackers";

    if (queryParam) {
      url = `${url}?${queryParam}`;
    }

    const { error, response } = await GET<DeliveryTrackerListResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const formatted = response.delivery_trackers.map((each) => {
      const {
          id,
          step,
          expected_amount,
          opportunity_details: {
            id: opp_id,
            name: opp_name,
            opportunity_property_id,
            slug,
          },
          ops_actual_date,
          ops_budget_date,
          status,
          property_details: { id: prop_id, location_id, name: prop_name },
          payment_due_date,
          ageing,
          outstanding_amount,
          phase,
          sub_phase,
        } = each,
        opportunity: ChapterDeliveryTrackerItem = {
          id: id || "",
          delivery_milestone: step || "",
          expected_amount: expected_amount,
          payment_due_date: payment_due_date || "",
          opp_id: opp_id || "",
          opp_name: opp_name || "",
          opportunity_property_id: opportunity_property_id || "",
          slug: slug || "",
          ops_actual_date: ops_actual_date || "",
          ops_budget_date: ops_budget_date || "",
          status: status || "",
          prop_id: prop_id || "",
          location_id: location_id || "",
          prop_name: prop_name || "",
          ageing: ageing,
          outstanding_amount: outstanding_amount,
          phase: phase || "",
          sub_phase: sub_phase || "",
        };

      return opportunity;
    });

    return {
      error: null,
      paginate: response.paginate,
      response: formatted,
    };
  }

  // Add Chapter Budget timelines
  async addChapterBudgetTimeline(
    trackerId: number,
    budgetTimelineData: ChapterBudgetTimeline,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/chapter/opportunity_properties/${trackerId}/delivery_timelines`,
      { error } = await POST<void>(url, budgetTimelineData);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  // update delivery tracker
  async updateChapterDeliveryTracker(
    selection_id: number,
    update_id: number,
    selection: any,
  ): Promise<FetchResult<void>> {
    const actual_payment_date = format(
        new Date(selection.actualpaymentDate),
        "yyyy-MM-dd",
      ),
      payload = {
        id: update_id,
        ops_budget_date: selection.OpsBudgetDate,
        ops_actual_date: selection.OpsActualDate,
        client_communication_date: selection.clientCommDate,
        payment_due_date: selection.paymentDueDate,
        actual_payment_date: actual_payment_date,
        client: selection.selected_client,
        delivery_timeline_details_attributes: [
          {
            id: selection.delivery_timeline_details_id,
            amount: selection.received,
            payment_date: actual_payment_date,
            gst_amount: selection.received_GST,
            stamp_duty_amount: selection.received_stamp,
            registration_amount: selection.received_registration,
            tds_amount: selection.received_tds,
            utr: selection.base_UTR,
            gst_utr: selection.gst_UTR,
            stamp_duty_utr: selection.stamp_UTR,
            registration_utr: selection.registration_UTR,
            account_type: selection.base_Account,
            gst_account_type: selection.gst_Account,
            registration_account_type: selection.registration_Account,
            stamp_duty_account_type: selection.stamp_Account,
            tds_account_type: selection.tds_Account,
            tds_paid_status: selection.payment_status,
            comment: selection.comment,
          },
        ],
      },
      url = `/api/v2/chapter/opportunity_properties/${selection_id}/delivery_timeline_details`,
      { error, response } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: response,
    };
  }

  async getChapterDeliveryTrackerClient(
    opportunity_slug: string,
  ): Promise<FetchResult<ChapterClientNameType[]>> {
    const url = `/api/v2/chapter/opportunities/${opportunity_slug}/clients`,
      { error, response } = await GET<DeliveryTrackerClientResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    if (Object.keys(response.clients).length === 0) {
      return {
        error: null,
        response: null,
      };
    }

    const formatted: ChapterClientNameType[] = response.clients;

    return {
      error: null,
      response: formatted,
    };
  }

  async getChapterDeliveryTrackerHistory(
    opportunity_slug: string,
    timeline_id: number,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<ChapterDeliveryTrackerHistory>> {
    const qp = new URLSearchParams();

    if (page) {
      qp.set("page", page);
    }

    if (page_size) {
      qp.set("per_page", page_size);
    }

    const marshalled = qp.toString();

    let url = `/api/v2/chapter/opportunities/${opportunity_slug}/delivery_timelines/${timeline_id}/payment_history`;
    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<DeliveryTrackerHistoryResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    if (Object.keys(response.history).length === 0) {
      return {
        error: null,
        response: null,
      };
    }

    const {
        client_communication_date,
        delivery_timeline_id,
        phase,
        step,
        ops_actual_date,
        ops_budget_date,
        tds_paid_date,
        tds_status,
        delivery_timeline_details,
      } = response.history,
      selection: ChapterDeliveryTrackerHistory = {
        delivery_timeline_id: delivery_timeline_id,
        client_communication_date: client_communication_date || "",
        phase: phase || "",
        step: step || "",
        ops_actual_date: ops_actual_date || "",
        ops_budget_date: ops_budget_date || "",
        tds_paid_date: tds_paid_date || "",
        tds_status: tds_status || "",
        delivery_timeline_details: delivery_timeline_details.map(
          (each: any) => {
            const {
              base_amount,
              client,
              gst,
              id,
              registration,
              stamp_duty,
              payment_date,
            } = each;
            return {
              id,
              base_amount: base_amount || 0,
              gst: gst || 0,
              registration: registration || 0,
              stamp_duty: stamp_duty || 0,
              client_name: client.name || "",
              payment_date: payment_date || "",
            };
          },
        ),
      },
      formatted = selection;

    return {
      error: null,
      response: formatted,
      paginate: response.paginate,
    };
  }

  async getChapterDeliveryTrackerHistoryByID(
    opportunity_slug: string,
    timeline_id: number,
    payment_history_id: number,
  ): Promise<FetchResult<ChapterDeliveryTrackerHistoryGetById>> {
    const url = `/api/v2/chapter/opportunities/${opportunity_slug}/delivery_timelines/${timeline_id}/delivery_timeline_details/${payment_history_id}`,
      { error, response } = await GET<DeliveryTrackerHistoryGetByIdResponse>(
        url,
      );

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    if (Object.keys(response.delivery_timeline_detail).length === 0) {
      return {
        error: null,
        response: null,
      };
    }

    const { id, payment_date, payment_due_date, client, breakdown } =
        response.delivery_timeline_detail,
      selection: ChapterDeliveryTrackerHistoryGetById = {
        id,
        payment_date,
        payment_due_date,
        client,
        breakdown,
      },
      formatted = selection;

    return {
      error: null,
      response: formatted,
    };
  }

  async generateChapterPaymentReciept(
    opportunity_slug: string,
    delivery_timeline_id: number,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/chapter/opportunities/${opportunity_slug}/delivery_timelines/${delivery_timeline_id}/generate_receipt`,
      { error } = await POST<void>(url, null);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  // Get Chapter steps data
  async getChapterBudgetTimelineStep(
    main_phase: string,
    sub_phase: string,
    chapter_property_id?: number,
  ): Promise<FetchResult<ChapterStepSelection[]>> {
    const url = `/api/v2/chapter/delivery_timeline_phase_details?phase=${main_phase}&sub_phase=${sub_phase}&property_id=${chapter_property_id}`,
      { error, response } = await GET<StepSelectionResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted = response.steps.map((each: any) => {
      const {
          id,
          step,
          delivery_timeline_config_details: {
            display_ops_actual_date,
            display_ops_budget_date,
            display_registration_fee_flat,
            display_registration_fee_percentage,
            display_stamp_duty_flat,
            display_stamp_duty_percentage,
          },
        } = each,
        selection: ChapterStepSelection = {
          id: id,
          step: step,
          display_ops_actual_date: display_ops_actual_date,
          display_ops_budget_date: display_ops_budget_date,
          display_registration_fee_flat: display_registration_fee_flat,
          display_registration_fee_percentage:
            display_registration_fee_percentage,
          display_stamp_duty_flat: display_stamp_duty_flat,
          display_stamp_duty_percentage: display_stamp_duty_percentage,
        };

      return selection;
    });

    return {
      error: null,
      response: formatted,
    };
  }

  // Get update budget timeline API
  // Fetches data when we open bulk update budget timeline
  async getChapterUpdateBudgetTimeline(
    opportunity_slug: string,
    phase: string,
    subPhase: string,
    timelineId?: number,
  ): Promise<FetchResult<ChapterUpdateBudgetTimeline>> {
    let url = `/api/v2/chapter/opportunities/${opportunity_slug}/opportunity_properties`;

    const qp = new URLSearchParams();

    if (phase) {
      qp.set("phase", phase);
    }

    if (subPhase) {
      qp.set("sub_phase", subPhase);
    }

    if (timelineId) {
      qp.set("timeline_id", timelineId.toString());
    }

    const marshaled = qp.toString();

    if (marshaled) {
      url = `${url}?${marshaled}`;
    }

    const { error, response } = await GET<PropertySelectionResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    if (Object.keys(response.opportunity_properties).length === 0) {
      return {
        error: null,
        response: null,
      };
    }

    const {
        id,
        chapter_opportunity_id,
        chapter_property_id,
        delivery_timelines,
        latest_payment_schedule_version,
        payment_details: { ats, iwc, common_amenities, add_ons, additional },
        property: {
          location_details: { city, country, state },
        },
        sub_phase_approval_details: { comment, status },
        tds_details: { latest_tds_value, enable_tds_input },
      } = response.opportunity_properties ?? {
        delivery_timelines: [],
      },
      selection: ChapterUpdateBudgetTimeline = {
        id: id || 0,
        chapter_opportunity_id: chapter_opportunity_id || "",
        chapter_property_id: chapter_property_id || 0,
        ats_breakup: ats,
        iwc_breakup: iwc,
        common_amenities_breakup: common_amenities,
        add_ons_breakup: add_ons,
        additional_breakup: additional,
        city: city || "",
        country: country || "",
        state: state || "",
        version: latest_payment_schedule_version || "",
        approval_comment: comment || "",
        approval_status: status || "",
        tds_percentage_parent: latest_tds_value || 0,
        enable_tds_input: enable_tds_input || false,
        budget_timelines: delivery_timelines.map((each: any) => {
          const {
            id,
            approve,
            payment_date,
            budget_intimation_date,
            delivery_timeline_phase_detail_id,
            step,
            sub_phase,
            status,
            amount_pending,
            expected_amount,
            ops_actual_date,
            ops_budget_date,
            client_communication_date,
            pending_days,
            collected_amount_breakdown,
            requested_amount_breakdown,
            selected_bank_accounts,
            is_demand_letter_generated,
            tds_paid_status,
            delivery_timeline_config_details: {
              display_ops_actual_date,
              display_ops_budget_date,
              display_registration_fee_flat,
              display_registration_fee_percentage,
              display_stamp_duty_flat,
              display_stamp_duty_percentage,
            },
          } = each;
          return {
            id,
            approve: approve,
            payment_date,
            budget_intimation_date,
            delivery_milestone: step,
            delivery_timeline_phase_detail_id,
            sub_phase,
            status,
            amount_pending,
            expected_amount,
            ops_actual_date,
            ops_budget_date,
            client_communication_date,
            pending_status: pending_days?.status ?? "",
            days: pending_days?.days ?? "",
            is_demand_letter_generated: is_demand_letter_generated,
            step_total:
              requested_amount_breakdown?.requested_amount_without_tds ?? 0,
            gst_percentage: requested_amount_breakdown?.gst_percentage ?? 0,
            stamp_duty_percentage:
              requested_amount_breakdown?.stamp_duty_percentage ?? 0,
            stamp_duty_flat_amount:
              requested_amount_breakdown?.stamp_duty_flat_amount ?? 0,
            registration_percentage:
              requested_amount_breakdown?.registration_percentage ?? 0,
            registration_flat_amount:
              requested_amount_breakdown?.registration_flat_amount ?? 0,
            tds_percentage: requested_amount_breakdown?.tds_percentage ?? 0,
            gst_amount: requested_amount_breakdown?.requested_gst_amount ?? "",
            stamp_duty_amount:
              requested_amount_breakdown?.requested_stamp_duty_amount ?? "",
            registration_amount:
              requested_amount_breakdown?.requested_registration_amount ?? "",
            tds_amount: requested_amount_breakdown?.requested_tds_amount ?? "",
            collected_gst_amount: collected_amount_breakdown?.gst_amount ?? 0,
            collected_stamp_duty_amount:
              collected_amount_breakdown?.stamp_duty_amount ?? 0,
            collected_registration_amount:
              collected_amount_breakdown?.registration_amount ?? 0,
            collected_tds_amount: collected_amount_breakdown?.tds_amount ?? 0,
            baseAmountBankAccount: selected_bank_accounts?.account_type ?? "",
            gstBankAccount: selected_bank_accounts?.gst_account_type ?? "",
            registrationBankAccount:
              selected_bank_accounts?.registration_account_type ?? "",
            stampDutyBankAccount:
              selected_bank_accounts?.stamp_duty_account_type ?? "",
            tdsBankAccount: selected_bank_accounts?.tds_account_type ?? "",
            tds_paid_status: tds_paid_status || "",
            display_ops_actual_date: display_ops_actual_date,
            display_ops_budget_date: display_ops_budget_date,
            display_registration_fee_flat: display_registration_fee_flat,
            display_registration_fee_percentage:
              display_registration_fee_percentage,
            display_stamp_duty_flat: display_stamp_duty_flat,
            display_stamp_duty_percentage: display_stamp_duty_percentage,
          };
        }),
      },
      formatted = selection;

    return {
      error: null,
      response: formatted,
    };
  }

  // Chapter Approval API
  async chapterBudgetTimelineApproval(
    id: number,
    opportunity_id: string,
    phase: string,
    sub_phase: string,
    approval_status: string,
    comment?: string,
  ): Promise<FetchResult<void>> {
    const payload = {
      opportunity_id: opportunity_id,
      phase: phase,
      sub_phase: sub_phase,
      approval_status: approval_status,
      comment: approval_status === "rejected" ? comment : "",
    };

    const url = `/api/v2/chapter/opportunity_properties/${id}/approve_reject`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: error,
      response: null,
    };
  }

  /**
   * Demand Letter Generation API
   */
  async generateChapterDemandLetter(
    opportunity_id: number,
    selection_id: number,
  ): Promise<FetchResult<void>> {
    const payload = {
      delivery_timeline_ids: selection_id,
      opportunity_property_id: opportunity_id,
    };

    const url = `/api/v2/chapter/delivery_timelines/generate_demand_letter`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async getChapterSmartSheetDetails(
    propertyType?: string,
    stepId?: string,
    opportunityPropertyId?: string,
  ): Promise<FetchResult<ChapterSmartSheetDetails>> {
    const url = `/api/v2/chapter/delivery_timeline_phase_smartsheet_data?property_type=${propertyType}&step_id=${stepId}&opportunity_property_id=${opportunityPropertyId}`,
      { error, response } = await GET<SmartSheetResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const { ops_budget_date, ops_actual_date, client_communication_date } =
        response,
      selection: ChapterSmartSheetDetails = {
        ops_budget_date: ops_budget_date,
        ops_actual_date: ops_actual_date,
        client_communication_date: client_communication_date,
      };

    return {
      error: null,
      response: selection,
    };
  }

  /**
   * Used to get Version List
   */
  async getChapterVersion(
    opportunity_slug: string,
  ): Promise<FetchResult<ChapterVersionData[]>> {
    const url = `/api/v2/chapter/opportunities/${opportunity_slug}/payment_schedule_versions`,
      { error, response } = await GET<PaymentVersionResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted = response.versions.map((each: any) => {
      const {
          delivery_timeline_phase_name,
          chapter_opportunity_property_id,
          id,
          name,
          status,
        } = each,
        selection: ChapterVersionData = {
          phase: delivery_timeline_phase_name,
          property_id: chapter_opportunity_property_id,
          id: id,
          name: name,
          status: status,
        };

      return selection;
    });

    return {
      error: null,
      response: formatted,
    };
  }

  // Guest Management
  async getChapterGuests(
    opportunity_slug: string,
  ): Promise<FetchResult<ChapterGuest[]>> {
    const url = `/api/v2/chapter/opportunities/${opportunity_slug}/guests`,
      { error, response } = await GET<GuestResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("unknown error"),
        response: null,
      };
    }

    const guests: ChapterGuest[] = response.guests.map((each) => {
      const {
          id,
          name,
          relationship,
          documents,
          dob,
          gender,
          likes,
          dislikes,
          primary_email_details,
          primary_mobile_details,
        } = each,
        nameSplits = (name || "").split(" "),
        guest: ChapterGuest = {
          id: id,
          first_name: nameSplits[0] || "",
          last_name: nameSplits.length > 1 ? nameSplits.slice(1).join(" ") : "",
          date_of_birth: dob || "",
          gender: gender,
          email: primary_email_details
            ? {
                id: primary_email_details.id,
                value: primary_email_details.email,
              }
            : undefined,
          phone_no: primary_mobile_details
            ? {
                id: primary_mobile_details.id,
                value: `${primary_mobile_details.country_code} ${primary_mobile_details.mobile}`,
              }
            : undefined,
          relationship: relationship
            ? {
                id: relationship.id,
                secondary_contact_id: relationship.relative_contact_id,
                value: relationship.relation,
              }
            : undefined,
          document: documents?.length
            ? {
                id: documents[0].id,
                type: documents[0].document_type,
                value: documents[0].document_identifier,
                file: documents[0].link,
              }
            : undefined,
          likes: likes,
          dislikes: dislikes,
        };

      return guest;
    });

    return {
      error: null,
      response: guests,
    };
  }

  async addChapterGuest(
    opportunity_slug: string,
    primary_contact_id: number,
    first_name: string,
    last_name: string,
    gender: string,
    date_of_birth?: string,
    email?: IndexedValue,
    phone_no?: IndexedValue,
    secondary_contact_id?: number,
    relationship_value?: string,
    document_type?: string,
    document_value?: string,
    document_file?: File,
    likes?: string[],
    dislikes?: string[],
  ): Promise<FetchResult<void>> {
    const formData = new FormData();

    let name = first_name;

    if (last_name) {
      name = `${name} ${last_name}`;
    }

    formData.set("name", name);

    if (gender) {
      formData.set("gender", gender);
    }

    if (date_of_birth !== undefined) {
      formData.set("dob", date_of_birth);
    }

    if (email !== undefined) {
      if (email.id !== undefined) {
        formData.set("primary_email_attributes[id]", email.id.toString());
      }
      formData.set("primary_email_attributes[email]", email.value);
    }

    if (phone_no !== undefined) {
      if (phone_no.id !== undefined) {
        formData.set("primary_mobile_attributes[id]", phone_no.id.toString());
      }

      const [countryCode, phoneNo] = phone_no.value.split(" ");

      formData.set("primary_mobile_attributes[country_code]", countryCode);

      formData.set("primary_mobile_attributes[mobile]", phoneNo);
    }

    if (primary_contact_id !== undefined) {
      formData.set("contact_id", primary_contact_id.toString());
    }

    if (secondary_contact_id !== undefined) {
      formData.set("relative_contact_id", secondary_contact_id.toString());
    }

    if (relationship_value !== undefined) {
      if (secondary_contact_id !== undefined) {
        formData.set(
          "relationships_attributes[0][relative_contact_id]",
          secondary_contact_id.toString(),
        );
      }
      formData.set("relationships_attributes[0][relation]", relationship_value);
    }

    if (document_type !== undefined && document_file !== undefined) {
      formData.set("documents_attributes[0][document_type]", document_type);
      formData.set("documents_attributes[0][file]", document_file);
      if (document_value !== undefined) {
        formData.set(
          "documents_attributes[0][document_identifier]",
          document_value,
        );
      }
    }

    if (likes) {
      likes.forEach((each) => formData.append("likes[]", each));
    }

    if (dislikes) {
      dislikes.forEach((each) => formData.append("dislikes[]", each));
    }

    const url = `/api/v2/chapter/opportunities/${opportunity_slug}/guests`,
      { error } = await POST<void>(url, formData);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updateChapterGuest(
    opportunity_slug: string,
    primary_contact_id: number,
    guest_id: number,
    first_name: string,
    last_name: string,
    gender: string,
    date_of_birth?: string,
    email?: IndexedValue,
    phone_no?: IndexedValue,
    relationship_id?: number,
    secondary_contact_id?: number,
    relationship_value?: string,
    document_id?: number,
    document_type?: string,
    document_value?: string,
    document_file?: File | string,
    likes?: string[],
    dislikes?: string[],
  ): Promise<FetchResult<void>> {
    const formData = new FormData();

    let name = first_name;

    if (last_name) {
      name = `${name} ${last_name}`;
    }

    formData.set("name", name);

    if (gender) {
      formData.set("gender", gender);
    }

    if (date_of_birth !== undefined) {
      formData.set("dob", date_of_birth);
    }

    if (email !== undefined) {
      const isStarHiddenValue = email?.value?.includes("*");

      if (email.id !== undefined && !isStarHiddenValue) {
        formData.set("primary_email_attributes[id]", email.id.toString());
      }
      !isStarHiddenValue &&
        formData.set("primary_email_attributes[email]", email.value);
    }

    if (phone_no !== undefined) {
      const isStarHiddenValue = phone_no?.value?.includes("*");

      if (phone_no.id !== undefined && !isStarHiddenValue) {
        formData.set("primary_mobile_attributes[id]", phone_no.id.toString());
      }

      if (phone_no.value.length > 4 && !isStarHiddenValue) {
        const [countryCode, phoneNo] = phone_no.value.split(" ");

        formData.set("primary_mobile_attributes[country_code]", countryCode);

        formData.set("primary_mobile_attributes[mobile]", phoneNo);
      }
    }

    if (primary_contact_id !== undefined) {
      formData.set("contact_id", primary_contact_id.toString());
    }

    if (
      relationship_value !== undefined &&
      secondary_contact_id !== undefined
    ) {
      if (relationship_id !== undefined) {
        formData.set(
          "relationships_attributes[0][id]",
          relationship_id.toString(),
        );
      }
      formData.set(
        "relationships_attributes[0][relative_contact_id]",
        secondary_contact_id.toString(),
      );
      formData.set("relationships_attributes[0][relation]", relationship_value);
    }

    if (document_type !== undefined) {
      if (document_id !== undefined) {
        formData.set("documents_attributes[0][id]", document_id.toString());
      }
      if (document_value !== undefined) {
        formData.set(
          "documents_attributes[0][document_identifier]",
          document_value,
        );
      }
      formData.set("documents_attributes[0][document_type]", document_type);
      if (document_file instanceof File) {
        formData.set("documents_attributes[0][file]", document_file);
      }

      if (document_id !== undefined && document_file === undefined) {
        formData.set("documents_attributes[0][_destroy]", "true");
      }
    }

    if (likes) {
      likes.forEach((each) => formData.append("likes[]", each));
    }

    if (dislikes) {
      dislikes.forEach((each) => formData.append("dislikes[]", each));
    }

    const url = `/api/v2/chapter/opportunities/${opportunity_slug}/guests/${guest_id}`,
      { error } = await PATCH<void>(url, formData);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async deleteChapterGuest(
    opportunity_slug: string,
    guest_id: number,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/chapter/opportunities/${opportunity_slug}/guests/${guest_id}`,
      { error } = await DELETE<void>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  //Attachments
  async getChapterDocuments(
    opportunity_slug: string,
    document_type?: string,
  ): Promise<FetchResult<ChapterDocument[]>> {
    let url = `/api/v2/chapter/opportunities/${opportunity_slug}/documents`;
    const qp = new URLSearchParams();

    if (document_type) {
      qp.set("document_type", document_type);
    }
    const marshaled = qp.toString();
    if (marshaled) {
      url = `${url}?${marshaled}`;
    }

    const { error, response } = await GET<ChapterDocumentsResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted: ChapterDocument[] = response.documents.map((each) => {
      const { id, link, document_name, document_identifier, document_type } =
          each,
        formatted: ChapterDocument = {
          id: id,
          type: document_type || "",
          name: document_name || document_identifier || "",
          file: link || "",
        };

      return formatted;
    });

    return {
      error: null,
      response: formatted,
    };
  }

  async addChapterDocument(
    opportunity_slug: string,
    document_type: string,
    document_name: string,
    file: File,
  ): Promise<FetchResult<void>> {
    const formData = new FormData();
    formData.set("document_type", document_type);
    formData.set("document_name", document_name);
    formData.set("file", file);

    const url = `/api/v2/chapter/opportunities/${opportunity_slug}/documents`,
      { error } = await POST<void>(url, formData);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async deleteChapterDocument(
    opportunity_slug: string,
    document_id: number,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/chapter/opportunities/${opportunity_slug}/documents/${document_id}`,
      { error } = await DELETE<void>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  //Ho Attachments
  async getChapterHoDocuments(
    property_id: number,
    document_type?: string,
  ): Promise<FetchResult<ChapterDocument[]>> {
    let url = `/api/v2/chapter/property_attachments?property_id=${property_id}`;
    const qp = new URLSearchParams();

    if (document_type) {
      qp.set("document_type", document_type);
    }
    const marshaled = qp.toString();
    if (marshaled) {
      url = `${url}?${marshaled}`;
    }

    const { error, response } = await GET<ChapterHoDocumentsResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted: ChapterDocument[] = response.home_owner_attachments.map(
      (each) => {
        const { id, url, document_identifier, document_type } = each,
          formatted: ChapterDocument = {
            id: id,
            type: document_type || "",
            name: document_identifier || "",
            file: url || "",
          };

        return formatted;
      },
    );

    return {
      error: null,
      response: formatted,
    };
  }

  async addChapterHoDocument(
    property_id: number,
    document_type: string,
    document_name: string,
    file: File,
  ): Promise<FetchResult<void>> {
    const formData = new FormData();
    formData.set("document_type", document_type);
    formData.set("document_identifier", document_name);
    formData.set("file", file);

    const url = `/api/v2/chapter/property_attachments?property_id=${property_id}`,
      { error } = await POST<void>(url, formData);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async deleteChapterHoDocument(
    property_id: number,
    document_id: number,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/chapter/property_attachments/${document_id}?property_id=${property_id}`,
      { error } = await DELETE<void>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  // Site Gallery

  async getChapterSiteGalleries(
    property_id: number,
    document_type?: string,
  ): Promise<FetchResult<ChapterSiteGalleryDocument[]>> {
    let url = `/api/v2/chapter/site_galleries?property_id=${property_id}`;
    const qp = new URLSearchParams();

    if (document_type) {
      qp.set("document_type", document_type);
    }
    const marshaled = qp.toString();
    if (marshaled) {
      url = `${url}?${marshaled}`;
    }

    const { error, response } = await GET<ChapterSiteGalleryResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted: ChapterSiteGalleryDocument[] = response.site_galleries.map(
      (each) => {
        const { id, url, created_at } = each,
          formatted: ChapterSiteGalleryDocument = {
            id: id,
            created_at: created_at || "",
            file: url || "",
          };

        return formatted;
      },
    );

    return {
      error: null,
      response: formatted,
    };
  }

  async addChapterSiteGallery(
    property_id: number,
    file: File,
  ): Promise<FetchResult<void>> {
    const formData = new FormData();
    formData.set("property_id", property_id.toString());
    formData.set("file", file);

    const url = `/api/v2/chapter/site_galleries`,
      { error } = await POST<void>(url, formData);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async deleteChapterSiteGallery(
    property_id: number,
    document_id: number,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/chapter/site_galleries/${document_id}?property_id=${property_id}`,
      { error } = await DELETE<void>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async getClientLedgerChapter(
    opportunity_id: string,
    start_date?: string,
    end_date?: string,
    timelines?: string[],
  ): Promise<FetchResult<DevelopmentClientLedger>> {
    const qp = new URLSearchParams();
    if (start_date) {
      qp.set("start_date", start_date);
    }
    if (end_date) {
      qp.set("end_date", end_date);
    }
    if (timelines) {
      timelines.forEach((timelineType) => {
        qp.append("timeline_types[]", timelineType);
      });
    }

    const queryParam = qp.toString();

    let url = `/api/v2/chapter/opportunities/${opportunity_id}/clients_ledger/list`;

    if (queryParam) {
      url = `${url}?${queryParam}`;
    }

    const { error, response } = await GET<ClientLedgerListResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const ledger_entries = response.entries.map((each) => {
      const {
          payment_date,
          milestone,
          base_amount,
          gst,
          stamp_duty,
          registration_fee,
          tds_amount,
          total_amount,
        } = each,
        ledger_entry: DevelopmentClientLedgerItem = {
          payment_date: payment_date,
          milestone: milestone,
          base_amount: base_amount,
          gst: gst,
          stamp_duty: stamp_duty,
          registration_fee: registration_fee,
          tds_amount: tds_amount,
          total_amount: total_amount,
        };

      return ledger_entry;
    });

    const formatted = {
      ledger_entries: ledger_entries,
      total: response.totals,
      timeline_type_headers: response.timeline_type_headers,
    };

    return {
      error: null,
      // paginate: response.paginate,
      response: formatted,
    };
  }

  getClientLedgerStatementChapter(
    opportunity_id: string,
    start_date?: string,
    end_date?: string,
    timelines?: string[],
  ): string {
    const qp = new URLSearchParams();
    if (start_date) {
      qp.set("start_date", start_date);
    }
    if (end_date) {
      qp.set("end_date", end_date);
    }
    if (timelines) {
      timelines.forEach((timelineType) => {
        qp.append("timeline_types[]", timelineType);
      });
    }

    const queryParam = qp.toString();

    let url = `/api/v2/chapter/opportunities/${opportunity_id}/clients_ledger/generate.pdf`;

    if (queryParam) {
      url = `${url}?${queryParam}`;
    }

    return url;
  }
}

export default ChapterService;
