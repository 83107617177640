import { Pagination } from "../types/pagination";
import {
  GenerateQuote,
  RentalCalendarAgent,
  RentalCalendarDayReservation,
  RentalCalendarProperty,
  RentalCalendarPropertyDetails,
  RentalCalendarTentative,
  RentalCalendarTentativeRate,
  TentativeRatesArg,
} from "../types/rental-calendar";
import { Coupon, PriceBreakdown } from "../types/rental-payment";
import { IRentalCalendarService } from "../types/services/rental-calendar-service";
import { DELETE, FetchResult, GET, PATCH, POST } from "../utils/fetch";

class RentalCalendarService implements IRentalCalendarService {
  async getCalendar(
    start_date?: string,
    end_date?: string,
    location_id?: string,
    propertyHighlight_id?: string[],
    propertyType?: string[],
    bedroom_id?: string,
    adult_id?: string,
    property_slug?: string,
    config_id?: string,
    checkinDate?: string,
    checkoutDate?: string,
    property_detail?: string,
    search?: string,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<RentalCalendarProperty[]>> {
    const qp = new URLSearchParams();

    if (start_date !== undefined) {
      qp.set("start_date", start_date);
    }

    if (end_date !== undefined) {
      qp.set("end_date", end_date);
    }

    if (checkinDate !== undefined) {
      qp.set("check_in", checkinDate);
    }

    if (checkoutDate !== undefined) {
      qp.set("check_out", checkoutDate);
    }

    if (location_id !== undefined) {
      qp.set("location_id", location_id.toString());
    }

    if (propertyHighlight_id?.length) {
      propertyHighlight_id.forEach((each) => qp.append("highlights[]", each));
    }

    if (propertyType?.length) {
      propertyType.forEach((each) => qp.append("property_types[]", each));
    }

    if (bedroom_id !== undefined) {
      qp.set("bedrooms", bedroom_id.toString());
    }

    if (adult_id !== undefined) {
      qp.set("occupancy", adult_id.toString());
    }

    if (property_slug !== undefined) {
      qp.set("property_slug", property_slug);
    }

    if (config_id !== undefined) {
      qp.set("property_config_id", config_id);
    }

    if (property_detail !== undefined) {
      qp.set("sales_channel", property_detail);
    }

    if (search) {
      qp.set("query", search);
    }

    if (page) {
      qp.set("page", page);
    }

    if (page_size) {
      qp.set("per_page", page_size);
    }

    const url = `/api/v2/rental/calendar?${qp}`,
      { error, response } = await GET<RentalCalendarResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    return {
      error: null,
      response: response.properties,
      paginate: response.paginate,
    };
  }

  async getTentativeRates(
    args: TentativeRatesArg[],
    global?: Coupon,
    opportunitySlug?: string,
  ): Promise<FetchResult<RentalCalendarTentative>> {
    if (!args.length) {
      return {
        error: null,
        response: null,
      };
    }

    const payload: any = {
      reservations: args.map((each) => {
        const {
            agent_id,
            property_slug,
            checkin_date,
            checkout_date,
            adults,
            children,
            coupon,
            breakdown,
            config_id,
            is_opted,
            display_protect_widget,
            quote_id,
          } = each,
          formatted: any = {
            agent_id: agent_id,
            property_slug: property_slug,
            check_in: checkin_date,
            check_out: checkout_date,
            adults: adults || "1",
            property_config_id: config_id,
            is_opted: is_opted,
            display_protect_widget: display_protect_widget,
            quote_id: quote_id,
          };

        if (children) {
          formatted.children = children;
        }

        if (coupon) {
          if (coupon.code === "MANUAL") {
            formatted.discount_type = coupon.type;
            formatted.discount_value = coupon.value;
          } else {
            formatted.discount_type = "coupon";
            formatted.discount_value = coupon.code;
          }
        }

        if (breakdown) {
          const {
            deposit,
            preferred_rate,
            agent_cutting_commission,
            commission_gst_applicable,
            commission_tds_applicable,
            commission_percentage,
            gst_commission_percentage,
            tds_commission_percentage,
            tds,
            acquisition_cost,
            ivpl_budget,
            premium,
          } = breakdown;

          formatted.preferred_rate = preferred_rate;

          formatted.deposit = deposit;

          formatted.agent_cutting_commission = agent_cutting_commission;

          formatted.commission_gst_applicable = commission_gst_applicable;

          formatted.commission_tds_applicable = commission_tds_applicable;

          formatted.commission_percentage = commission_percentage;

          formatted.gst_commission_percentage = gst_commission_percentage;

          formatted.tds_commission_percentage = tds_commission_percentage;

          formatted.tds = tds;

          formatted.caq = acquisition_cost;

          formatted.ivpl_budget = ivpl_budget;

          formatted.premium = premium;
        }
        return formatted;
      }),
    };

    if (opportunitySlug) {
      payload.opportunity_slug = opportunitySlug || null;
    }

    if (global) {
      const { type, value } = global;
      payload.discount_type = type;
      payload.discount_value = value;
    }

    const url = "/api/v2/rental/calendar/rates",
      { error, response } = await POST<TentativeRatesResponse>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const tentativeRates = response.rates.map((each: any, index: number) => {
        const arg = args[index],
          {
            amount,
            breakdown: rawBreakdown,
            property_slug,
            property_name,
            number_of_nights,
            number_of_marketing_nights,
            property_config_id = arg.config_id,
            display_protect_widget,
            is_opted,
            widget_details,
            refundable_booking_opted,
          } = each,
          {
            average_base_rate,
            average_adults_rate,
            average_children_rate,
            gross_base_rate,
            total_taxes,
            convenience_fee,
            preferred_rate,
            deposit,
            commission,
            agent_cutting_commission,
            commission_gst_applicable,
            commission_tds_applicable,
            commission_percentage,
            gst_commission_percentage,
            tds_commission_percentage,
            discount,
            discount_type,
            discount_value,
            coupon_code,
            tds,
            caq,
            ivpl_budget,
            premium,
            currency_symbol,
            extra_adults_fare,
          } = rawBreakdown || ({} as any),
          breakdown: PriceBreakdown = {
            average_base_rate: average_base_rate || 0,
            average_adults_rate: average_adults_rate || 0,
            average_children_rate: average_children_rate || 0,
            gross_base_rate: gross_base_rate || 0,
            taxes: total_taxes || 0,
            convenience_fee: convenience_fee || 0,
            preferred_rate: preferred_rate || 0,
            deposit: deposit || 0,
            commission: commission || 0,
            agent_cutting_commission: agent_cutting_commission,
            commission_gst_applicable: commission_gst_applicable,
            commission_tds_applicable: commission_tds_applicable,
            commission_percentage: commission_percentage || 0,
            gst_commission_percentage: gst_commission_percentage || 0,
            tds_commission_percentage: tds_commission_percentage || 0,
            discount: discount || 0,
            discount_type: discount_type || undefined,
            discount_value: discount_value || undefined,
            coupon_code: coupon_code || undefined,
            tds: tds || undefined,
            acquisition_cost: caq || undefined,
            ivpl_budget: ivpl_budget || undefined,
            premium: premium || undefined,
            currency_symbol: currency_symbol || "",
            extra_adults_fare: extra_adults_fare || 0,
          },
          formatted: RentalCalendarTentativeRate = {
            ...arg,
            property_name: property_name || "",
            property_slug: property_slug || args[index].property_slug,
            amount: amount ?? 0,
            breakdown: breakdown,
            number_of_nights,
            number_of_marketing_nights,
            config_id: property_config_id,
            is_opted: is_opted || true,
            display_protect_widget:
              display_protect_widget || args[index].display_protect_widget,
            quote_id: widget_details.quote_id || arg.quote_id,
            show_refundable_switch: refundable_booking_opted,
          };

        let coupon: Coupon | undefined = undefined;

        if (discount_type) {
          if (discount_type === "coupon") {
            coupon = arg.coupon;
          } else {
            coupon = {
              id: -1,
              code: "MANUAL",
              name: "MANUAL",
              type: discount_type,
              value: discount_value,
              active: false,
              auto_applicable_on_website: false,
              visible_on_website: false,
              tax_on_gross_amount: false,
            };
          }
        }

        formatted.coupon = coupon;

        return formatted;
      }),
      { total = {} } = response,
      totalFormatted = {
        ...total,
        breakdown: {
          ...(total.breakdown ?? {}),
          taxes: total.breakdown?.total_taxes ?? 0,
          currency_symbol: total.breakdown?.currency_symbol || "",
        },
      };

    return {
      error: null,
      response: {
        coupon: global ? global : tentativeRates[0].coupon || undefined,
        total: totalFormatted,
        rates: tentativeRates,
      },
    };
  }

  async getAgentDataById(
    id: string,
  ): Promise<FetchResult<RentalCalendarAgent>> {
    const url = `/api/v2/agents/${id}/dropdown`,
      { response, error } = await GET<AgentOptionById>(url);

    if (error) {
      return {
        response: null,
        error: error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const {
        name,
        value,
        is_onboarded,
        is_gst_mapped,
        default_gst_value,
        default_tds_value,
      } = response.agent,
      formatted: RentalCalendarAgent = {
        name: name,
        value: value,
        is_onboarded: is_onboarded,
        is_gst_mapped: is_gst_mapped,
        default_gst_value: default_gst_value,
        default_tds_value: default_tds_value,
      };

    return {
      response: formatted,
      error: null,
    };
  }

  async bookRates(
    opportunity_slug: string,
    rates: TentativeRatesArg[],
    globalCoupon?: Coupon,
    is_gst_mapped?: boolean,
    is_onboarded?: boolean,
  ): Promise<FetchResult<void>> {
    const payload: any = {
      reservations: rates.map((each) => {
        const {
            agent_id,
            property_slug,
            checkin_date,
            checkout_date,
            adults,
            children,
            coupon,
            reservation_slug,
            breakdown,
            config_id,
            quote_id,
            is_opted,
            display_protect_widget,
          } = each,
          formatted: any = {
            agent_id: agent_id,
            reservation_slug: reservation_slug,
            property_slug: property_slug,
            check_in: checkin_date,
            check_out: checkout_date,
            adults: adults || "1",
            property_config_id: config_id,
            quote_id: quote_id,
            is_opted: is_opted,
            display_protect_widget: display_protect_widget,
          };

        if (children) {
          formatted.children = children;
        }

        if (coupon) {
          if (coupon.code === "MANUAL") {
            if (coupon.type === "marketing_nights") {
              formatted.discount_type = "marketing_nights";
              formatted.discount_value = 100;
            } else {
              formatted.discount_type = coupon.type;
              formatted.discount_value = coupon.value;
            }
          } else {
            formatted.discount_type = "coupon";
            formatted.discount_value = coupon.code;
          }
        }

        // if (globalCoupon && !coupon) {
        //   const { type, value } = globalCoupon;
        //   formatted.discount_type = type;
        //   formatted.discount_value = value;
        // }

        if (breakdown) {
          const {
            deposit,
            preferred_rate,
            agent_cutting_commission,
            commission_gst_applicable,
            commission_tds_applicable,
            commission_percentage,
            gst_commission_percentage,
            tds_commission_percentage,
            tds,
            acquisition_cost,
            ivpl_budget,
            premium,
          } = breakdown;

          formatted.preferred_rate = preferred_rate;

          formatted.deposit = deposit;

          formatted.agent_cutting_commission = agent_cutting_commission;

          formatted.commission_gst_applicable = commission_gst_applicable;

          formatted.commission_tds_applicable = agent_cutting_commission
            ? commission_tds_applicable
            : true;

          formatted.commission_percentage = commission_percentage;

          formatted.gst_commission_percentage = agent_cutting_commission
            ? gst_commission_percentage
            : commission_gst_applicable
            ? is_onboarded && !is_gst_mapped
              ? 0
              : 18
            : 0;

          formatted.tds_commission_percentage = agent_cutting_commission
            ? tds_commission_percentage
            : 5;

          formatted.tds = tds;

          formatted.caq = acquisition_cost;

          formatted.ivpl_budget = ivpl_budget;

          formatted.premium = premium;
        }

        return formatted;
      }),
    };

    const url = `/api/v2/rental/opportunities/${opportunity_slug}/reservations/multi_update`,
      { error } = await PATCH<any>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async blockDates(
    property_slug: string,
    config_id: string,
    start_date: string,
    end_date: string,
    status: string,
    comment: string,
  ): Promise<FetchResult<void>> {
    const payload = {
        property_slug: property_slug,
        property_config_id: config_id,
        check_in: start_date,
        check_out: end_date,
        status: status,
        comment: comment,
      },
      url = "/api/v2/rental/calendar/status",
      { error } = await PATCH<void>(url, payload);
    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async unblockDates(
    property_slug: string,
    config_id: string,
    start_date: string,
    end_date: string,
  ): Promise<FetchResult<void>> {
    const payload = {
        property_slug: property_slug,
        property_config_id: config_id,
        check_in: start_date,
        check_out: end_date,
      },
      url = "/api/v2/rental/calendar/unblock",
      { error } = await DELETE<void>(url, payload);
    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async syncInventory(property_slug: string): Promise<FetchResult<void>> {
    const url = `/api/v2/rental/properties/${property_slug}/sync_inventory`,
      { error } = await POST<void>(url, null);
    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async syncRates(property_slug: string): Promise<FetchResult<void>> {
    const url = `/api/v2/rental/properties/${property_slug}/sync_rates`,
      { error } = await POST<void>(url, null);
    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async uploadCalendarCSV(file: File): Promise<FetchResult<void>> {
    const url = `/api/v2/rental/properties/upload_calendar`,
      formData = new FormData();
    formData.set("file", file);
    const { error } = await POST<void>(url, formData);
    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      response: null,
      error: null,
    };
  }

  async getReservation(
    property_slug: string,
    date: string,
  ): Promise<FetchResult<RentalCalendarDayReservation>> {
    const qp = new URLSearchParams();
    qp.set("property_slug", property_slug);
    qp.set("date", date);

    const url = `/api/v2/rental/calendar/status?${qp}`,
      { error, response } = await GET<ReservationResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const { reservation } = response;
    if (!reservation) {
      return {
        error: new Error("No reservation available"),
        response: null,
      };
    }

    const { opportunity_slug, check_in, check_out, adults, children, name } =
        reservation,
      formatted: RentalCalendarDayReservation = {
        opportunity_slug: opportunity_slug,
        checkin_date: check_in,
        checkout_date: check_out,
        guests: adults.toString(),
        name: name || "",
      };
    if (children) {
      formatted.guests += ` ${children}`;
    }

    return {
      response: formatted,
      error: null,
    };
  }

  async getPropertiesDetails(
    property_slug: string,
  ): Promise<FetchResult<RentalCalendarPropertyDetails>> {
    const url = `/api/v2/rental/properties/${property_slug}/details`,
      { error, response } = await GET<PropertyDetailsResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const {
        additional_info,
        brochure_url,
        house_rules_url,
        meal_menu_url,
        activity_brochure_url,
      } = response,
      formatted: RentalCalendarPropertyDetails = {
        additional_info: additional_info,
        brochure_url: brochure_url,
        house_rules_url: house_rules_url,
        meal_menu_url: meal_menu_url,
        activities_url: activity_brochure_url,
      };

    return {
      response: formatted,
      error: null,
    };
  }

  async generateQuote(
    args: TentativeRatesArg[],
    opportunitySlug?: string,
  ): Promise<FetchResult<GenerateQuote>> {
    if (!args.length) {
      return {
        error: null,
        response: null,
      };
    }

    const payload: any = {
      reservations: args.map((each) => {
        const {
            agent_id,
            property_slug,
            checkin_date,
            checkout_date,
            adults,
            children,
            coupon,
            breakdown,
            config_id,
            is_opted,
            display_protect_widget,
            quote_id,
          } = each,
          formatted: any = {
            agent_id: agent_id,
            property_slug: property_slug,
            check_in: checkin_date,
            check_out: checkout_date,
            adults: adults || "1",
            property_config_id: config_id,
            is_opted: is_opted,
            display_protect_widget: display_protect_widget,
            quote_id: quote_id,
          };

        if (children) {
          formatted.children = children;
        }

        if (coupon) {
          if (coupon.code === "MANUAL") {
            formatted.discount_type = coupon.type;
            formatted.discount_value = coupon.value;
          } else {
            formatted.discount_type = "coupon";
            formatted.discount_value = coupon.code;
          }
        }

        if (breakdown) {
          const {
            deposit,
            preferred_rate,
            agent_cutting_commission,
            commission_gst_applicable,
            commission_tds_applicable,
            commission_percentage,
            gst_commission_percentage,
            tds_commission_percentage,
            tds,
            acquisition_cost,
            ivpl_budget,
            premium,
            coupon_code,
          } = breakdown;

          formatted.preferred_rate = preferred_rate;

          formatted.deposit = deposit;

          formatted.agent_cutting_commission = agent_cutting_commission;

          formatted.commission_gst_applicable = commission_gst_applicable;

          formatted.commission_tds_applicable = commission_tds_applicable;

          formatted.commission_percentage = commission_percentage;

          formatted.gst_commission_percentage = gst_commission_percentage;

          formatted.tds_commission_percentage = tds_commission_percentage;

          formatted.tds = tds;

          formatted.caq = acquisition_cost;

          formatted.ivpl_budget = ivpl_budget;

          formatted.premium = premium;

          formatted.coupon_code = coupon_code;
        }
        return formatted;
      }),
    };

    if (opportunitySlug) {
      payload.opportunity_slug = opportunitySlug || null;
    }

    const url = "/api/v2/rental/property_quotations",
      { error, response } = await POST<GenerateQuoteResponse>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    return {
      error: error,
      response: response,
    };
  }
}

export default RentalCalendarService;

type RentalCalendarResponse = {
  properties: RentalCalendarProperty[];
  paginate: Pagination;
};

type TentativeRatesResponse = {
  rates: any;
  total: any;
};

type ReservationResponse = {
  reservation: any;
};

type PropertyDetailsResponse = {
  additional_info: string;
  brochure_url: string;
  house_rules_url: string;
  meal_menu_url: string;
  activity_brochure_url: string;
};

type AgentOptionById = {
  agent: any;
};

type GenerateQuoteResponse = {
  message: any;
};
